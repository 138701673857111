<template>
	<div>
		<div class="venueInformation-box">
			<div class="name">国家体育馆介绍</div>
			<img src="../../../../assets/eventtheme/guanc.png"alt="">
		</div>

		<div class="brief">
			国家体育馆是名副其实的“双奥场馆”，于2005年5月28日开工，2008年3月完成建设，功能需求完全按照大型国际体育赛事管理流程、比赛标准完成，是目前亚洲最大的室内综合体育馆。作为2008年北京奥运会三大主场馆之一，国家体育馆位于地处北京中轴线的北京奥林匹克公园中心区南部。
		</div>

		<div>
			<div class="bt">
				<div class="title">
					面积
				</div>
			</div>

			<div class="xian"></div>

			<div class="content">
				<div class="title brief1" style="margin-right: 56px;margin-top: 44px;">
					为满足冬奥会赛事功能，国家体育馆进行科学的规划改造，目前，场馆总面积约9.8万平方米，同时配置两块符合奥运赛事标准的冰球场地，可以实现快速的冰陆转换，是北京地区规模最大、设施最先进的综合性室内文化体育活动场馆。
				</div>
				<div class="img">
					<img src="../../../../assets/eventtheme/guanc2.png" alt="">
				</div>
			</div>

			<div class="bt">
				<div class="title">
					荣誉
				</div>
			</div>

			<div class="xian"></div>

			<div class="content">
				<div class="title brief1">
					国家体育馆顺利保障2022年冬奥会和冬残奥会45场冰球比赛（冬奥29场、残奥16场）和256场官方训练，圆满完成国家体育馆冬奥会冰球比赛和冬残奥会残奥冰球比赛的保障任务。得到了国际奥委会、国际残奥委会、国际冰联、国际残奥冰联和北京冬奥组委及各国运动员、官员的高度肯定和赞扬。
				</div>
			</div>

			<div class="bt">
				<div class="title">
					位置
				</div>
			</div>

			<div class="xian"></div>

			<div class="content">
				<div class="img2">
					<img src="../../../../assets/eventtheme/guanc1.png" alt="">
				</div>
				<div class="title brief1" style="margin-left: 56px;margin-top: 44px;">国家体育馆位于奥林匹克公共区安保区的西北角，距离运动员酒店约3公里，行车时间约为10分钟。</div>
			</div>
		</div>

		<div style="height: 107px;"></div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {}
		},
		created() {},
		methods: {}
	}
</script>

<style scoped lang="scss">
	.venueInformation-box {
		width: 1300px;
		height: 597px;
		background: #FFFFFF;
		border-radius: 299px;
		margin: 68px auto 0;

		img {
			width: 100%;
			height: 100%;
			border-radius: 299px;
		}

		.name {
			width: 1194px;
			height: 130px;
			margin-left: 56px;
			background: rgba(24, 17, 35, 0.5);
			border-radius: 299px;
			font-size: 54px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 465px;
			background: url('../../../../assets/eventtheme/bany.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}

	.brief {
		width: 1434px;
		margin: 0 auto;
		background: #EAF7FF;
		border-radius: 23px;
		font-size: 29px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		padding: 60px;
		margin-top: 32px;
		line-height: 50px;
		text-align: justify;
		text-indent: 60px;
	}
	
	.brief1{
		text-indent: 60px;
	}

	.bt {
		width: 1300px;
		margin: 0 auto;
		margin-top: 92px;
		display: flex;
		justify-content: center;

		.title {
			width: 135px;
			height: 56px;
			font-size: 33px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			background: url('../../../../assets/eventtheme/title4.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.xian {
		width: 1300px;
		height: 1px;
		background: #5B5B5B;
		border-radius: 0px;
		margin: 0 auto;
		margin-top: 14px;
	}

	.content {
		display: flex;
		width: 1300px;
		margin: 0 auto;
		margin-top: 64px;

		.title {
			font-size: 29px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			line-height: 58px;
			text-align: justify;
		}
		
		.img{
			width: 633px;
			height: 426px;
			flex-shrink: 0;
			img {
				width: 100%;
				height: 100%;
				border-radius: 30px;
			}
		}
		
		.img2{
			width: 533px;
			height: 533px;
			flex-shrink: 0;
			img {
				width: 100%;
				height: 100%;
				border-radius: 30px;
			}
		}

	}
</style>