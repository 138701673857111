<template>
	<div class="box">
		<div class="banner-box">
			<div class="banner" v-for="(item,index) in date" :key="index" @click="jump(item.id)">
				<div class="time">[{{item.publishTime | format}}]</div>
				<div class="title">{{item.title}} </div>
			</div>
			<!-- <div class="banner">
				<div class="time"> [2023-12-08]</div>
				<div class="title">2023年世界花样滑冰大奖赛总决赛 </div>
			</div>
			<div class="banner">
				<div class="time"> [2023-12-08]</div>
				<div class="title">2023年世界花样滑冰大奖赛总决赛 </div>
			</div> -->
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="18" :pageSize="18"></pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				total: 0,
				date:[],
				pageNum: 1,
				pageSize: 18,
			}
		},
		created() {
			this.getArticleListById()
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			currentchange(e) {
				this.pageNum = e
				this.getArticleListById()
			},
			getArticleListById() {
				let data = {
					id: this.$route.query.navid,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.getArticleListById(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.list
					
					this.date.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/iceskating/details',
					query: {
						subjectid: id,
						logo: this.$route.query.logo,
						navindex: this.$route.query.navindex,
						id:this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
		}
	}
</script>

<style scoped lang="scss">
	.box {
		width: 1305px;
		margin: 87px auto 0;

		.banner-box {
			display: flex;
			flex-wrap: wrap;

			.banner {
				width: 630px;
				height: 176px;
				background: #FFFFFF;
				border: 1px solid #C8DBE2;
				border-radius: 1px 1px 87px 1px;
				margin-right: 45px;
				margin-bottom: 49px;
				cursor: pointer;

				.time {
					font-size: 21px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #7D7D7D;
					margin-top: 33px;
					margin-left: 41px;
				}

				.title {
					width: 553px;
					font-size: 23px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 23px;
					margin-left: 41px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					line-height: 38px;
				}
			}
			.banner:hover{
				width: 630px;
				height: 176px;
				background: linear-gradient(0deg, #159AFF 0%, #8FCEFF 100%);
				border-radius: 1px 1px 87px 1px;
				
				.time {
					color: #fff !important;
				}
				
				.title {
					color: #fff !important;
				}
			}

			.banner:nth-child(2n) {
				margin-right: 0;
			}
		}

		.paging {
			width: 1200px;
			display: flex;
			justify-content: center;
			padding-bottom: 73px;

		}
	}
</style>