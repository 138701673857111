<template>
	<div>
		<div class="box">
			<img src="../../../../assets/eventtheme/beij.png" alt="" class="logo">
			<div class="jinj" v-if="date[3]">
				<div class="title" @click="jump(date[3].id)">{{date[3].title}}</div>
				<div class="brief" @click="jump(date[3].id)" v-html="getText(date[3].content)"></div>
				<div class="time-box">
					<div class="time">[{{date[3].publishTime | format}}]</div>
					<div class="bottom" @click="jump(date[3].id)">
						查看详情
						<img src="../../../../assets/eventtheme/more.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="recommend-box" v-if="date">
			<div class="recommend" @mouseover="changeIndex(1)" @mouseout="changeIndexBack(1)">
				<img src="../../../../assets/eventtheme/recommend1.png" alt="" v-if="!recommend1">
				<div class="title-box" v-else @click="jump(date[2].id)">
					<div class="title">知名景点</div>
					<div class="brief" v-html="getText(date[2].content)"></div>
				</div>
			</div>
			<div class="recommend" @mouseover="changeIndex(2)" @mouseout="changeIndexBack(2)">
				<img src="../../../../assets/eventtheme/recommend2.png" alt="" v-if="!recommend2">
				<div class="title-box" v-else @click="jump(date[1].id)">
					<div class="title">购物&商圈</div>
					<div class="brief" v-html="getText(date[1].content)"></div>
				</div>
			</div>
			<div class="recommend" @mouseover="changeIndex(3)" @mouseout="changeIndexBack(3)">
				<img src="../../../../assets/eventtheme/recommend3.png" alt="" v-if="!recommend3">
				<div class="title-box" v-else @click="jump(date[0].id)">
					<div class="title">美食文化</div>
					<div class="brief" v-html="getText(date[0].content)"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				recommend1: false,
				recommend2: false,
				recommend3: false,
				recommend4: false,
				recommend5: false,
				recommend6: false,
				date: [],
				pageNum: 1,
				pageSize: 20,
			}
		},
		created() {
			this.getArticleListById()
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			getArticleListById() {
				let data = {
					id: this.$route.query.navid,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.getArticleListById(data).then(res => {
					console.log(res.data.data)
					this.total = res.data.data.total
					this.date = res.data.data.list

					this.date.forEach(item => {
						item.title = item.title.replace(/<br>/g, ' ')
					})
				})
			},
			getText(str) {
				const regExp = /style="[^"]*"/g; // 匹配style属性及其值
				const plainText = str.replace(regExp, ''); // 去除style属性
				const imgRegExp = /<img[^>]*>/g
				const plainText1 = plainText.replace(imgRegExp, ''); // 去除img
				return plainText1;
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/iceskating/details',
					query: {
						subjectid: id,
						logo: this.$route.query.logo,
						navindex: this.$route.query.navindex,
						id: this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			changeIndex(e) {
				if (e == 1) {
					this.recommend1 = true
				} else if (e == 2) {
					this.recommend2 = true
				} else if (e == 3) {
					this.recommend3 = true
				}
			},
			changeIndexBack(e) {
				if (e == 1) {
					this.recommend1 = false
				} else if (e == 2) {
					this.recommend2 = false
				} else if (e == 3) {
					this.recommend3 = false
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.box {
		width: 1303px;
		margin: 104px auto 0;
		display: flex;

		.logo {
			width: 658px;
			height: 403px;
		}

		.jinj {
			margin-left: 35px;

			.title {
				width: 574px;
				font-size: 33px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				cursor: pointer;
				height: 90px;
			}

			.brief {
				width: 574px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				margin-top: 29px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
				line-height: 42px;
				text-align: justify;
				cursor: pointer;
				text-indent: 40px;
			}

			.time-box {
				display: flex;
				align-items: baseline;
				justify-content: space-between;

				.time {
					font-size: 21px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					opacity: 0.54;
					margin-top: 94px;
				}

				.bottom {
					width: 250px;
					height: 47px;
					background: #F8FDFF;
					border: 1px solid #000000;
					border-radius: 23px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					img {
						width: 30px;
						height: 13px;
						margin-left: 15px;
					}
				}
			}
		}
	}

	.recommend-box {
		width: 1303px;
		margin: 0 auto;
		margin-top: 133px;
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 40px;

		.recommend {
			width: 401px;
			height: 430px;
			margin-right: 50px;
			margin-bottom: 57px;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}

			.title-box {
				width: 401px;
				height: 430px;
				margin-right: 50px;
				margin-bottom: 57px;
				background: linear-gradient(0deg, #0064FF, #5ADCFF);
				opacity: 0.8;
				border-radius: 19px;

				.title {
					font-size: 38px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					padding-top: 60px;
					text-align: center;
				}

				.brief {
					font-size: 21px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #FFFFFF;
					padding: 0 30px;
					margin-top: 48px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 6;
					overflow: hidden;
					line-height: 42px;
					text-align: justify;
					
				}
			}
		}

		.recommend:nth-child(3n) {
			margin-right: 0;
		}
	}
</style>