<template>
	<div>
		<div class="ticketing">
			<div class="ticketing-box">
				<div class="title">2023年世界花样滑冰大奖赛总决赛</div>
				<div class="time-box">
					<div class="nian">2023</div>
					<div class="nian1">12/07</div>
					<div class="nian2">SAT</div>
					<div class="nian3"></div>
					<div class="nian4">12/10</div>
					<div class="nian5">sun</div>
				</div>
			</div>
			<div class="rubric">公众售票官方网站</div>
			<div class="butbox">
				<div class="button" @click="getpurchase(1)">境内购票</div>
				<div class="button button2" @click="getpurchase(2)">境外购票</div>
			</div>
			<div class="bottom">
				<div class="time">2023/12.07</div>
				<div class="title">距离2023年世界花样滑冰大奖赛总决赛</div>
				<div class="djs">倒计时：</div>
				<div class="num">{{gettitme}}</div>
				<div class="tian">天</div>
			</div>
		</div>
		
		<!-- <div class="biaot">购票指南</div>
		<div class="xian-box">
			<div class="xian"></div>
		</div>
		
		<div class="guide-box">
			<div class="guide">
				<img src="../../../../assets/eventtheme/status7.png" alt="" class="logo">
				<div class="nr-box" @click="getJump(1)">
					<div class="name">项目详情</div>
					<img src="../../../../assets/eventtheme/return.png" alt="" class="teturn">
				</div>
			</div>
			<div class="guide">
				<img src="../../../../assets/eventtheme/status6.png" alt="" class="logo logo1" style="width: 69.2px;height: 69.2px;">
				<div class="nr-box nr-box1" @click="getJump(2)">
					<div class="name">购票须知</div>
					<img src="../../../../assets/eventtheme/return.png" alt="" class="teturn">
				</div>
			</div>
			<div class="guide">
				<img src="../../../../assets/eventtheme/status5.png" alt="" class="logo logo2" style="width: 73px;height: 67.6px;">
				<div class="nr-box nr-box1" @click="getJump(3)">
					<div class="name name1" >帮助</div>
					<img src="../../../../assets/eventtheme/return.png" alt="" class="teturn">
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				date:[]
			}
		},
		computed: {
			gettitme: function() {
				const targetDate = new Date('2023-12-07');
				// 获取当前日期的时间戳（以毫秒为单位）
				const currentDate = new Date();
				const currentTimestamp = currentDate.getTime();
				// 获取目标日期的时间戳（以毫秒为单位）
				const targetTimestamp = targetDate.getTime();
				// 计算时间戳差值（以毫秒为单位）
				const timeDifference = targetTimestamp - currentTimestamp;
				// 将毫秒转换为天数
				const millisecondsPerDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
				const daysDifference = Math.floor(timeDifference / millisecondsPerDay);
				return daysDifference
			}
		},
		created() {
			this.getArticleListById()
		},
		methods: {
			getArticleListById() {
				let data = {
					id: this.$route.query.navid,
					pageNum: 1,
					pageSize: 10,
				}
				this.$api.getArticleListById(data).then(res => {
					this.date = res.data.data.list
			
					this.date.forEach(item => {
						item.title = item.title.replace(/<br>/g, ' ')
					})
				})
			},
			getJump(e){
				if(e == 1){
					for(let i in this.date){
						if(this.date[i].title.trim() == '项目详情'){
							let routeData = this.$router.resolve({
								path: '/iceskating/details',
								query: {
									subjectid: this.date[i].id,
									logo: this.$route.query.logo,
									navindex: this.$route.query.navindex,
									id:this.$route.query.id,
									refresh: new Date().getTime()
								}
							});
							window.open(routeData.href, '_blank');
						}
					}
				}else if(e == 2){
					for(let i in this.date){
						if(this.date[i].title.trim() == '购票须知'){
							let routeData = this.$router.resolve({
								path: '/iceskating/details',
								query: {
									subjectid: this.date[i].id,
									logo: this.$route.query.logo,
									navindex: this.$route.query.navindex,
									id:this.$route.query.id,
									refresh: new Date().getTime()
								}
							});
							window.open(routeData.href, '_blank');
						}
					}
				}else{
					window.open('https://help.damai.cn/helpPage.htm','_blank')
				}
			},
			getpurchase(e){
				if(e == 1){
					// window.open('https://detail.damai.cn/item.htm?id=745785317724', '_blank');
				}else{
					
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.ticketing {
		width: 1200px;
		height: 550px;
		background: url('../../../../assets/eventtheme/ticket.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin: 115px auto 0;
	
		.ticketing-box {
			display: flex;
			padding-top: 71px;
			margin-left: 85px;
	
			.title {
				font-size: 33px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #57B7FF;
			}
	
			.time-box {
				display: flex;
				align-items: flex-start;
				margin-left: 123px;
			}
	
			.nian {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #0091FF;
				line-height: 122px;
				transform: rotate(90deg);
				margin-top: -34px;
			}
	
			.nian1 {
				font-size: 38px;
				font-family: Barlow Condensed SemiBold;
				font-weight: 400;
				color: #0091FF;
			}
	
			.nian2 {
				font-size: 31px;
				font-family: Abel;
				font-weight: 400;
				color: #0091FF;
				margin-left: 9px;
				margin-top: 7px;
			}
	
			.nian3 {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-left: 24px solid #0091FF;
				border-bottom: 12px solid transparent;
				margin-left: 17px;
				margin-top: 16px;
			}
	
			.nian4 {
				font-size: 38px;
				font-family: Barlow Condensed SemiBold;
				font-weight: 400;
				color: #0091FF;
				margin-left: 14px;
			}
	
			.nian5 {
				font-size: 31px;
				font-family: Abel;
				font-weight: 400;
				color: #0091FF;
				margin-left: 11px;
				margin-top: 6px;
			}
		}
	
		.rubric {
			font-size: 58px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #57B7FF;
			margin-left: 86px;
			margin-top: -20px;
		}
		
		.butbox{
			display: flex;
		}
		.button {
			width: 260px;
			height: 77px;
			background: #FFFFFF;
			border-radius: 9px;
			font-size: 29px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #00BDFF;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 81px;
			margin-left: 91px;
			cursor: pointer;
		}
		.button2{
			margin-left: 21px !important;
		}
	
		.bottom {
			display: flex;
			align-items: flex-end;
	
			.time {
				font-size: 38px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 102px;
			}
	
			.title {
				font-size: 23px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 27px;
				position: relative;
				top: -6px;
			}
	
			.djs {
				font-size: 25px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 137px;
			}
	
			.num {
				font-size: 79px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 16px;
				position: relative;
				top: 19px;
			}
	
			.tian {
				font-size: 25px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 16px;
			}
		}
	}
	
	.biaot{
		font-size: 38px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		width: 1200px;
		margin: 87px auto 0;
		text-align: center;
	}
	
	.xian-box{
		width: 1200px;
		margin: 29px auto 0;
		display: flex;
		justify-content: center;
		.xian{
			width: 83px;
			height: 8px;
			background: #0091FF;
			border-radius: 4px;
		}
	}
	
	.guide-box{
		display: flex;
		width: 1200px;
		margin: 103px auto 0;
		padding-bottom: 61px;
		.guide{
			width: 400px;
			height:261px ;
			background: url('../../../../assets/eventtheme/hbback6.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			
			.logo{
				width: 59.4px;
				height: 63.1px;
				margin-top: 48px;
				margin-left: 170px;
			}
			.logo1{
				width: 69.2px !important;
				height: 69.2px !important;
			}
			.logo2{
				width: 73.4px !important;
				height: 67.6px !important;
			}
			.nr-box{
				display: flex;
				align-items: center;
				margin-top: 35px;
				cursor: pointer;
				.name{
					font-size: 35px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-left: 115px;
				}
				.name1{
					margin-left: 140px !important;
				}
				.teturn{
					width: 18.7px;
					height: 26px;
					margin-left: 42px;
					margin-top: 5px;
				}
			}
			.nr-box1{
				margin-top: 30px !important;
			}
		}
	}
	
</style>