<template>
	<div class="box">
		<!-- <table>
			<tr>
				<th rowspan="5"></th>
			</tr>
		</table> -->
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {}
		},
		created() {},
		methods: {}
	}
</script>

<style scoped lang="scss">
	.box{
		width: 1200px;
		height: 1200px;
		background: url('../../../../assets/eventtheme/schedule2.png');
		background-repeat: no-repeat;
		background-size: contain;
		margin: 77px auto 0;
	}
</style>